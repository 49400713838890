<template>
  <el-container class="shopList ">
    <el-header class="box add">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>登记管理</el-breadcrumb-item>
        <el-breadcrumb-item>变更日志</el-breadcrumb-item>
      </el-breadcrumb>
      <div>
        <el-button class="back_btn" size="medium" plain @click="$router.back()">返回</el-button>
      </div>
    </el-header>
    <el-main class="p15 pt15">
      <el-row class="box   f14">
        <el-col :span="24" class="recordadd-title bgcfbe tl mb20 pl15">企业信息</el-col>

        <el-col :span="11" class="tl red mb20">
          <el-col :span="24" class="tl mb20">
            <span class="left">变更后企业名称：</span><span class="c333"
              :class="company.company_name!=lastcompany.company_name?'red':''">{{company.company_name}}</span>
          </el-col>
          <el-col :span="24" class="tl mb20">
            <span class="left">变更后监管类型：</span><span class="c333"
              :class="company_type_name!=company_type_name1?'red':''">{{company_type_name}}</span>
          </el-col>
          <el-col :span="24" class="tl mb20">
            <span class="left">变更后主体类型：</span><span class="c333"
              :class="company_tag_name!=company_tag_name1?'red':''">{{company_tag_name}}</span>
          </el-col>

          <el-col :span="24" class="tl mb20">
            <span class="left">变更后统一社会信用代码：</span><span class="c333"
              :class="company_detail.credit_code!=lastcompany_detail.credit_code?'red':''">{{company_detail.credit_code}}</span>
          </el-col>
          <el-col v-if="company.company_tag_id==4" :span="24" class="tl mb20">
            <span class="left">变更后备案证号：</span><span class="c333"
              :class="company_detail.record_code!=lastcompany_detail.record_code?'red':''">{{company_detail.record_code}}</span>
          </el-col>
          <el-col v-if="company.company_tag_id==4" :span="24" class="tl mb20">
            <span class="left">变更后食品经营许可证号：</span><span class="c333"
              :class="company_detail.record_code!=lastcompany_detail.record_code?'red':''">{{company_detail.record_code}}</span>
          </el-col>
          <el-col :span="24" class="tl mb20">
            <span class="left">变更后法人姓名：</span><span class="c333"
              :class="company_detail.legal_person_name!=lastcompany_detail.legal_person_name?'red':''">{{company_detail.legal_person_name}}</span>
          </el-col>
          <el-col :span="24" class="tl mb20">
            <span class="left">变更后法人电话：</span><span class="c333"
              :class="company_detail.mobilephone!=lastcompany_detail.mobilephone?'red':''">{{company_detail.mobilephone}}</span>
          </el-col>
          <el-col :span="24" class="tl mb20">
            <span class="left">变更后身份证号：</span><span class="c333"
              :class="company_detail.idcard!=lastcompany_detail.idcard?'red':''">{{company_detail.idcard}}</span>
          </el-col>
          <el-col v-if="company.company_tag_id==4" :span="24" class="tl mb20">
            <span class="left">变更后备案时间：</span><span class="c333"
              :class="company_detail.record_time!=lastcompany_detail.record_time?'red':''">{{company_detail.record_time}}</span>
          </el-col>
          <el-col v-if="company.company_tag_id==4" :span="24" class="tl mb20">
            <span class="left">变更后备案证号：</span><span class="c333"
              :class="company_detail.record_code!=lastcompany_detail.record_code?'red':''">{{company_detail.record_code}}</span>
          </el-col>
          <el-col v-if="company.company_tag_id==4" :span="24" class="tl mb20">
            <span class="left">变更后发证日期：</span><span class="c333"
              :class="company_detail.issue_time!=lastcompany_detail.issue_time?'red':''">{{company_detail.issue_time}}</span>
          </el-col>
          <el-col v-if="company.company_tag_id==4" :span="24" class="tl mb20">
            <span class="left">变更后有效期：</span><span class="c333"
              :class="company_detail.unuseful_time!=lastcompany_detail.unuseful_time?'red':''">{{company_detail.unuseful_time}}</span>
          </el-col>
          <el-col :span="24" class="tl mb20">
            <span class="left">变更后注册地址：</span><span class="c333"
              :class="company_detail.produce_address!=lastcompany_detail.produce_address?'red':''">{{company_detail.produce_address}}</span>
          </el-col>
          <el-col v-show="company.company_tag_id==3" :span="24" class="tl mb20">
            <span class="left">变更后发证机关：</span><span class="c333"
              :class="company_detail.issue_unit!=lastcompany_detail.issue_unit?'red':''">{{company_detail.issue_unit}}</span>
          </el-col>
          <el-col v-show="company.company_tag_id==3" :span="24" class="tl mb20">
            <span class="left">变更后签发日期：</span><span class="c333"
              :class="company_detail.issue_time!=lastcompany_detail.issue_time?'red':''">{{company_detail.issue_time}}</span>
          </el-col>
          <el-col :span="24" class="tl mb20">
            <span class="left">变更后经营场所面积：</span><span class="c333"
              :class="company_detail.place_area!=lastcompany_detail.place_area?'red':''">{{company_detail.place_area}}</span>
          </el-col>
          <el-col :span="24" class="tl mb20">
            <span class="left">变更后从业人数：</span><span class="c333"
              :class="company_detail.employ_num!=lastcompany_detail.employ_num?'red':''">{{company_detail.employ_num}}</span>
          </el-col>
          <el-col :span="24" class="tl mb20">
            <span class="left">变更后经营范围：</span><span class="c333"
              :class="company_detail.business_names!=lastcompany_detail.business_names?'red':''">{{company_detail.business_names}}</span>
          </el-col>
          <el-col v-if="company.company_tag_id!=4" :span="24" class="tl mb20">
            <span class="left">变更后经营类型：</span><span class="c333"
              :class="company_detail.business_type!=lastcompany_detail.business_type?'red':''">{{company_detail.business_type}}</span>
          </el-col>
          <el-col v-if="company.company_tag_id==4" :span="24" class="tl mb20">
            <span class="left">变更后主营项目：</span><span class="c333"
              :class="company_detail.main_subject_names!=lastcompany_detail.main_subject_names?'red':''">{{company_detail.main_subject_names}}</span>
          </el-col>
          <el-col v-if="company.company_tag_id==4" :span="24" class="tl mb20">
            <span class="left">变更后兼营项目：</span><span class="c333"
              :class="company_detail.sub_subject_names!=lastcompany_detail.sub_subject_names?'red':''">{{company_detail.sub_subject_names}}</span>
          </el-col>
          <el-col  :span="24" class="tl mb20">
            <span class="left">变更后经营储存条件：</span><span class="c333"
              :class="company_detail.storage_conditions!=lastcompany_detail.storage_conditions?'red':''">{{company_detail.storage_conditions}}</span>
          </el-col>
          <el-col :span="24" class="tl mb20">
            <span class="left">变更后经营商品类型：</span><span class="c333"
              :class="company_detail.commodity_type!=lastcompany_detail.commodity_type?'red':''">{{company_detail.commodity_type}}</span>
          </el-col>
        </el-col>
        <el-col :span="11" class="tl mb20">
          <el-col :span="24" class="tl red mb20">
            <span class="left">变更前企业名称：</span><span class="c333">{{lastcompany.company_name}}</span>
          </el-col>
          <el-col :span="24" class="tl red mb20">
            <span class="left">变更前监管类型：</span><span class="c333">{{company_type_name1}}</span>
          </el-col>
          <el-col :span="24" class="tl red mb20">
            <span class="left">变更前主体类型：</span><span class="c333">{{company_tag_name1}}</span>
          </el-col>
          <el-col :span="24" class="tl red mb20">
            <span class="left">变更前统一社会信用代码：</span><span class="c333">{{lastcompany_detail.credit_code}}</span>
          </el-col>
          <el-col v-if="company.company_tag_id==4" :span="24" class="tl red mb20">
            <span class="left">变更前备案证号：</span><span class="c333">{{lastcompany_detail.record_code}}</span>
          </el-col>
          <el-col v-if="company.company_tag_id==4" :span="24" class="tl red mb20">
            <span class="left">变更前食品经营许可证号：</span><span class="c333">{{lastcompany_detail.record_code}}</span>
          </el-col>
          <el-col v-show="company.company_tag_id==4" :span="24" class="tl  mb20">
            <span class="left">变更前经营者姓名：</span><span class="c333">{{lastcompany_detail.legal_person_name}}</span>
          </el-col>
          <el-col v-show="company.company_tag_id==3" :span="24" class="tl  mb20">
            <span class="left">变更前法人姓名：</span><span class="c333">{{lastcompany_detail.legal_person_name}}</span>
          </el-col>
          <el-col :span="24" class="tl red mb20">
            <span class="left">变更前法人电话：</span><span class="c333">{{lastcompany_detail.mobilephone}}</span>
          </el-col>
          <el-col :span="24" class="tl red mb20">
            <span class="left">变更前身份证号：</span><span class="c333">{{lastcompany_detail.idcard}}</span>
          </el-col>
          <el-col v-if="company.company_tag_id==4" :span="24" class="tl red mb20">
            <span class="left">变更前备案时间：</span><span class="c333">{{lastcompany_detail.record_time}}</span>
          </el-col>
          <el-col v-if="company.company_tag_id==4" :span="24" class="tl red mb20">
            <span class="left">变更前备案证号：</span><span class="c333">{{lastcompany_detail.record_code}}</span>
          </el-col>
          <el-col v-if="company.company_tag_id==4" :span="24" class="tl red mb20">
            <span class="left">变更前发证日期：</span><span class="c333">{{lastcompany_detail.issue_time}}</span>
          </el-col>
          <el-col v-if="company.company_tag_id==4" :span="24" class="tl red mb20">
            <span class="left">变更前有效期：</span><span class="c333">{{lastcompany_detail.unuseful_time}}</span>
          </el-col>
          <el-col :span="24" class="tl red mb20">
            <span class="left">变更前经营场所面积：</span><span class="c333">{{lastcompany_detail.place_area}}平方米（㎡）</span>
          </el-col>
          <el-col :span="24" class="tl  mb20">
            <span class="left">变更前注册地址：</span><span class="c333">{{lastcompany_detail.produce_address}}</span>
          </el-col>
          <el-col v-show="company.company_tag_id==3" :span="24" class="tl  mb20">
            <span class="left">变更前发证机关：</span><span class="c333">{{lastcompany_detail.issue_unit}}</span>
          </el-col>
          <el-col v-show="company.company_tag_id==3" :span="24" class="tl  mb20">
            <span class="left">变更前签发日期：</span><span class="c333">{{lastcompany_detail.issue_time}}</span>
          </el-col>
          <el-col :span="24" class="tl red mb20">
            <span class="left">变更前从业人数：</span><span class="c333">{{lastcompany_detail.employ_num}}</span>
          </el-col>
          <el-col :span="24" class="tl red mb20">
            <span class="left">变更前经营范围：</span><span class="c333">{{lastcompany_detail.business_names}}</span>
          </el-col>
          <el-col v-show="company.company_tag_id==4" :span="24" class="tl red mb20">
            <span class="left">变更前主营项目：</span><span class="c333">{{lastcompany_detail.main_subject_names}}</span>
          </el-col>
          <el-col v-show="company.company_tag_id==4" :span="24" class="tl red mb20">
            <span class="left">变更前主营项目：</span><span class="c333">{{lastcompany_detail.sub_subject_names}}</span>
          </el-col>
           <el-col  :span="24" class="tl  mb20">
            <span class="left">变更前经营储存条件：</span><span class="c333">{{lastcompany_detail.storage_conditions}}</span>
          </el-col>
          <el-col  :span="24" class="tl  mb20">
            <span class="left">变更前经营商品类型：</span><span class="c333">{{lastcompany_detail.commodity_type}}</span>
          </el-col>
        </el-col>

      </el-row>

    </el-main>
  </el-container>
</template>
<script>
import Vue from 'vue'
import axios from '../../../../util/api'
import { local, aa, objKeySort,session } from '../../../../util/util'
import { imageUrl } from '../../../../config/index'
import { mapState } from 'vuex'
import md5 from 'js-md5';
export default {
  data () {
    return {
      imageUrl: imageUrl,
      filing_state: '',
      activeNames: ['1'],
      user: '',
      timeVisible: false,
      uploadDisabled: false,
      uploadDisabled1: false,
      uploadDisabled2: false,
      uploadDisabled11: false,
      timer: '',
      loading: false,
      tableData: [],
      show: true,
      count: '',
      typetOptions: [],
      tagOptions: [],
      Options1: [],//生产形式
      Options2: [],//上产类别
      fileList: [],
      fileList1: [],
      fileList2: [],
      company_id: '',
      lastcompany: {},
      company_type_name1: '',
      company_type_name: '',
      company_tag_name1: "",
      company_tag_name: '',
      lastcompany_detail: {},
      company: {},
      company_detail: {},
      form: { List: [{ name: '', healthimg: '', time: '' }] },
      dialogImageUrl: '',
      dialogVisible: false,
      pickerOptionsEnd: {
        disabledDate: time => {
          return time.getTime() > Date.now()
        }
      },
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() < Date.now();
        },
      }
    }
  },
  created () {
    var that = this;
    that.user = local.get('user');
    this.company_id =session.get('params').id?String(session.get('params').id):'';
    that.getData({ company_id: this.company_id })
  },
  methods: {
    handleChange (val) {
    },
    getData (params) {
      var that = this;
      axios.get('/pc/company-log/last', params).then((v) => {
        if (v.data.content != null) {
          that.lastcompany = JSON.parse(v.data.content).company;
          that.lastcompany_detail = JSON.parse(v.data.content).company_detail;
          axios.get('/pc/company-type/all').then((v) => {
            var obj = {}
            obj = v.data.company_type_list.find(item => {
              return item.company_type_id == that.lastcompany.company_type_id;
            })
            that.company_type_name = obj.company_type_name
          })
          axios.get('/pc/company-tag/all', { company_type_id: String(that.lastcompany.company_type_id) }).then((v) => {
            var obj1 = {}
            obj1 = v.data.company_tag_list.find(item => {
              return item.company_tag_id == that.lastcompany.company_tag_id;
            })
            that.company_tag_name = obj1.company_tag_name;
          })
        }

      })
      axios.get('/pc/company/single', params).then((v) => {
        that.company = v.data.company;
        axios.get('/pc/company-type/all').then((v) => {
          var obj2 = {}
          obj2 = v.data.company_type_list.find(item => {
            return item.company_type_id == that.company.company_type_id;
          })
          that.company_type_name1 = obj2.company_type_name
        })
        axios.get('/pc/company-tag/all', { company_type_id: String(that.company.company_type_id) }).then((v) => {
          var obj3 = {}
          obj3 = v.data.company_tag_list.find(item => {
            return item.company_tag_id == that.company.company_tag_id;
          })
          that.company_tag_name1 = obj3.company_tag_name
        })
        this.getcompanyDetail(params)
      })
    },

    logTimeChange (val) { },

    getDictionary (params) {
      var that = this;
      axios.get('/pc/dictionary/all', { company_tag_id: '1' }).then((v) => {
        that.Options1 = v.data.dictionary_list.经营信息.生产形式;
        that.Options2 = v.data.dictionary_list.经营信息.生产类别;
      })
    },
    getcompanyDetail (params) {
      var that = this;
      axios.get('/pc/company-catering/single', params).then((v) => {
        that.company_detail = v.data.companyCatering;
      })
    },

  }
}
</script>
<style lang="scss" scoped>
.shopList {
  overflow-x: hidden;
  .add {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .active {
    background: #409eff;
    border-color: #409eff;
    color: #ffffff;
  }
  .back_btn {
    background: rgba(247, 248, 249, 1);
    border: 1px solid rgba(217, 222, 225, 1);
  }
  .recordadd-title {
    height: 34px;
    line-height: 34px;
    border-left: 2px solid #788287;
  }
  /deep/ .el-input-group__append .el-button--primary .el-icon-search {
    color: #fff;
  }
  /deep/ .el-checkbox-button__inner {
    border-left: 1px solid #dcdfe6;
    border-radius: 4px !important;
  }
  /deep/ .el-table.no-header .el-table__header {
    display: none;
  }
  .people-message .el-form-item {
    margin-bottom: 0px;
  }
  /deep/ .el-col-7 .el-form-item__content {
    width: 160px !important;
  }
  .recordadd-upload {
    width: 160px;
    height: 160px;
    float: left;
    margin-right: 10px;
    .disabled {
      height: 100%;
    }
    /deep/ .el-upload {
      width: 160px;
      height: 160px;
      line-height: 160px;
    }
    /deep/ .el-upload-list--picture-card .el-upload-list__item {
      width: 160px;
      height: 160px;
    }
  }
  .health_url {
    /deep/ .el-upload--picture-card {
      width: 70px;
      height: 40px;
      background-color: transparent;
      border: none;
      .el-button--medium {
        position: absolute;
        left: 10px;
      }
    }
    /deep/ .el-upload-list--picture-card .el-upload-list__item {
      width: 70px;
      height: 40px;
    }
  }
  table .cell .is-error {
    padding-bottom: 20px;
  }
  .el-table th.is-leaf,
  .el-table td {
    border-bottom: none;
  }
  /deep/ .disabled .el-upload--picture-card {
    display: none !important;
  }
  /deep/ .el-table {
    .el-table__body,
    .el-table__header {
      width: 100% !important;
    }
  }
  .el-checkbox-button {
    margin-bottom: 10px;
  }
}
</style>
